import DocumentTextIcon from '@heroicons/react/24/outline/DocumentTextIcon';
import ErrorIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon';
import {
  Button,
  Form,
  FormField,
  FormInput,
  twMerge,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  useBoolean,
} from 'atp-react-ui';

import { FormPasswordInput } from '@/_components';
import { useAuth } from '@/_providers';

import type { PrepCourseResourcesButtonProps } from '../prep-course-card/types';

import { useMoodleAuth } from './use-moodle-auth';

export function PrepCourseResourcesButton({ courseId, url }: PrepCourseResourcesButtonProps) {
  console.log('PrepCourseResources', { courseId, url });

  const openModal = useBoolean();
  const { logout } = useAuth();

  const { form, moodleLoginUrl, submitButtonRef, submitForm, onSubmit } = useMoodleAuth({ courseId });

  return (
    <>
      <Modal open={openModal[0]} disableInternalClosing hideCloseButton onClose={() => {}} className="mx-4 my-auto">
        <ModalBody className="flex flex-col items-center text-center">
          <Icon icon={ErrorIcon} className="size-16 text-accent-x-light" />
          <div className="mt-6 text-center text-accent-light">
            Oops, something went wrong. Kindly log in again and retry.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button modal onClick={logout}>
            Sign out
          </Button>
          <Button modal as="outline" onClick={openModal[1].setFalse}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Form {...form}>
        <form action={moodleLoginUrl} method="post" className={twMerge('flex-col')}>
          <div className="hidden">
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => <FormInput placeholder="xyz@abc.com" label="Email" {...field} />}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => <FormPasswordInput type="password" label="Password" {...field} />}
            />
          </div>

          <div onClick={onSubmit}>
            <Button
              ref={submitButtonRef}
              as="link"
              block
              type="submit"
              className="justify-between px-4"
              iconRight={DocumentTextIcon}
              showLoader={submitForm[0]}
            >
              Course Resources
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
}
