import BookOpenIcon from '@heroicons/react/24/outline/BookOpenIcon';
import InformationCircleIcon from '@heroicons/react/24/solid/InformationCircleIcon';
import { Button } from 'atp-react-ui';
import { Link } from 'react-router-dom';

import type { PrepCourseHeaderProps } from './types';

export function PrepCourseHeader({ id, imageUrl, alt, route }: PrepCourseHeaderProps) {
  return (
    <header className="course-card-article-header border-outline/30">
      {route && (
        <section className="course-card-header-actions">
          <Link to={route.buildPath({ id } as never)} state={{ section: 'study-guide' }}>
            <Button
              as="link"
              icon={BookOpenIcon}
              iconClassName="size-6"
              aria-label="View course details"
              className="rounded-xl bg-white p-1.5 shadow hover:bg-primary-x-light active:bg-primary-x-light"
            />
          </Link>
          <Link to={route.buildPath({ id } as never)}>
            <Button
              as="link"
              icon={InformationCircleIcon}
              iconClassName="size-6"
              aria-label="View course details"
              className="prep-course-detail-button rounded-xl bg-white p-1.5 shadow hover:bg-primary-x-light active:bg-primary-x-light"
            />
          </Link>
        </section>
      )}
      <img className="h-28 w-full object-cover" src={imageUrl} alt={alt} loading="lazy" />
    </header>
  );
}
